import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "homeView" */ "@/views/HomeView.vue"),
  },
  {
    path: "/discography",
    name: "discography",
    component: () =>
      import(
        /* webpackChunkName: "discographyView" */ "@/views/DiscographyView.vue"
      ),
  },
  {
    path: "/discography/:id",
    name: "album",
    component: () =>
      import(/* webpackChunkName: "albumView" */ "@/views/AlbumView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "aboutView" */ "@/views/AboutUsView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contactView" */ "@/views/ContactUsView.vue"),
  },
  {
    path: "/coming-soon",
    name: "soon",
    component: () =>
      import(/* webpackChunkName: "soonView" */ "@/views/SoonView.vue"),
  },
  {
    path: "/links",
    name: "soon",
    component: () =>
      import(/* webpackChunkName: "soonView" */ "@/views/Links.vue"),
  },
  {
    path: "/not-found",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/views/NotFoundView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "404" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
