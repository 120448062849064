import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TheFooter = _resolveComponent("TheFooter")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "main-background" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, { class: "main-vertical-flex" }, {
        default: _withCtx(() => [
          _createVNode(_component_TheHeader),
          _createVNode(_component_router_view),
          _createVNode(_component_TheFooter)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}